import React from 'react'
import ds from "../../controller/DataService";

export default async function Resolve(props) {
	let id = !!window && !!window.location && !!window.location.pathname ? window.location.pathname.substr(1) : '';
	return (
		<div style={{marginTop: '1em'}}>
			{window.location.assign(await ds.resolveLink(id))}
		</div>
	)
}
