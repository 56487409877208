import * as config from '../config'
import * as firebase from "firebase";

class DataService {
	constructor() {
		this.firebase = firebase.initializeApp(config.FIREBASE_CONFIG);
		this.firestore = this.firebase.firestore();
		this.functions = this.firebase.functions();
		this.user = [];
	}

	async resolveLink(short) {
		try {
			const data = await firebase.firestore().collection('shortLinks').doc(short).get();
			if (data.exists) {
				return data.data().url;
			}
		} catch (error) {
		}
		return 'https://myrealfood.app/get/';
	}
}

export default new DataService();
