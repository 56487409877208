import React from 'react';

import './App.css';
import {Route, Switch, withRouter} from 'react-router'
import Resolve from "./common/Resolve";


const app = function App() {
	return (
		<Switch>
			<Route path='*'>
				<Resolve to='/'/>
			</Route>
		</Switch>
	);
}

export default withRouter(app);
